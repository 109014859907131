<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.aboutProjectHeader') }}</b>
    </h3>
    <hr />
    <div v-html="$t('FAQ.payQ')"></div>
    <div v-html="$t('FAQ.payA')"></div>
    <hr />
    <div v-html="$t('FAQ.durationQ')"></div>
    <div v-html="$t('FAQ.durationA')"></div>
    <hr />
    <div v-html="$t('FAQ.otherFeeQ')"></div>
    <div v-html="$t('FAQ.otherFeeA')"></div>
    <hr />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
